import { NgModule, APP_INITIALIZER, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MaterialModule } from 'material.module';
import { AbpHttpInterceptor } from 'abp-ng2-module';

import { SharedModule } from '@shared/shared.module';
import { ServiceProxyModule } from '@shared/service-proxies/service-proxy.module';
import { ServiceSnackBarModule } from '@shared/notifications/service-snackbar.module';
import { RootRoutingModule } from './root-routing.module';
import { AppConsts } from '@shared/AppConsts';
import { API_BASE_URL } from '@shared/service-proxies/service-proxies';

import { RootComponent } from './root.component';
import { AppInitializer } from './app-initializer';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from './environments/environment';

import { CookieModule } from 'ngx-cookie';
import { APP_BASE_HREF } from '@angular/common';



export function getCurrentLanguage(): string {
  if (abp.localization.currentLanguage.name) {
    return abp.localization.currentLanguage.name;
  }

  // todo: Waiting for https://github.com/angular/angular/issues/31465 to be fixed.
  return 'es';
}

export function getTenant(): string {
  if (location.pathname.length <= 1) {
    AppConsts.tenant = null;
  } else {
    let list = location.pathname.split('/').filter(x => x != '');
    let tenantName = list[0].toLowerCase();
    let url = `${environment.remoteServiceBaseUrl}/api/services/app/Tenant/GetByName?name=${tenantName}`;

    const xhr = new XMLHttpRequest();
    xhr.open('GET', url, false);  // `false` makes the request synchronous
    xhr.send(null);

    let response = xhr.responseText != '' ? JSON.parse(xhr.responseText):null;
    AppConsts.tenant = response && response.result != null ? response.result : null;
  }
  return AppConsts.tenant != null ? AppConsts.tenant.tenancyName.toLowerCase() : '';
}

@NgModule({
  imports: [
    MaterialModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    CookieModule.forRoot(),
    SharedModule.forRoot(),
    ServiceProxyModule,
    ServiceSnackBarModule,
    RootRoutingModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.wpa,
      registrationStrategy: 'registerWhenStable:20000'
    })
  ],
  declarations: [RootComponent],
  providers: [
    {
      provide: APP_BASE_HREF,
      useFactory: getTenant
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AbpHttpInterceptor,
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (appInitializer: AppInitializer) => appInitializer.init(),
      deps: [AppInitializer],
      multi: true,
    },
    {
      provide: API_BASE_URL,
      useFactory: () => AppConsts.remoteServiceBaseUrl
    },
    {
      provide: LOCALE_ID,
      useFactory: getCurrentLanguage,
    },
  ],
  bootstrap: [RootComponent]
})
export class RootModule { }
