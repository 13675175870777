import { Injectable } from '@angular/core';
import { StringHelper } from '@shared/helpers/string-helper';
import { InvoiceServiceProxy } from '@shared/service-proxies/service-proxies';

@Injectable()

export class DownloadPdfInvoiceService {

  constructor(private _invoiceService: InvoiceServiceProxy) { }

  downloadInvoice(id: number): void {
    this.getOrderPDF(id).then(result => {
      let file = StringHelper.b64toBlob(result, 'application/pdf');
      var fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    });
  }

  private getOrderPDF(id: number): Promise<string> {
    return new Promise((resolve, reject) => {
      this._invoiceService.generatePDFInvoice(id).subscribe(result => {
        resolve(result);
      });
    });
  }
}
