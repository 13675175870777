import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AbpHttpInterceptor } from 'abp-ng2-module';
import * as Services from './service-snackbar';

@NgModule({
    providers: [
        Services.SnackBarService
    ]
})
export class ServiceSnackBarModule { }
