import { Injector, Pipe, PipeTransform } from '@angular/core';
import { AppComponentBase } from '@shared/app-component-base';

@Pipe({
  name: 'truncate'
})
export class TruncatePipe extends AppComponentBase implements PipeTransform {
    constructor(injector: Injector) {
        super(injector);
    }
    transform(value: string, limit = 25, completeWords = false, ellipsis = '...') {
        if (completeWords) {
        limit = value.substr(0, limit).lastIndexOf(' ');
        }
        return value.length > limit ? value.substr(0, limit) + ellipsis : value;
    }
}