<h2 mat-dialog-title>{{ title | localize}}</h2>
<form [formGroup]="fgRefer" (ngSubmit)="save()">

    <mat-form-field>
        <mat-label>{{'MinStock' | localize}}</mat-label>
        <input type="number" formControlName="minStock" matInput>
    </mat-form-field>

    <!-- Search Products -->
    <app-search-products [input]="'productInput'" [showSplitName]="true"[showStockQuantity]="false" [addQuantity]="false" (newData)="searchProductsFromComponent($event)"></app-search-products>

  <div class="row" formArrayName="listProducts">
    <div class="col">
        <table class="table table-fixed">
        <thead>
            <tr>
            <th>
                {{ 'Products' | localize }}
            </th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of productsArr.controls; let i = index">

            <td>
                {{ item.value.name }}
            </td>

            <td [formGroupName]="i" class="col-input">
                <button type="button" mat-icon-button color="warn" matTooltip="{{ 'Delete' | localize }}"
                aria-label="{{ 'Delete' | localize }}" (click)="deleteProduct(i)">
                <mat-icon>delete</mat-icon>
                </button>
            </td>
            </tr>
            <tr *ngIf="productsArr.length == 0">
            <td style="width: 600px;" colspan="2" class="text-center">{{ 'EmptyProducts'| localize }}</td>
            </tr>
        </tbody>
        </table>
    </div>
  </div>

</form>

<mat-dialog-actions align="end">
  <button mat-button (click)="onNoClick()">{{'Cancel' | localize }}</button>
  <button mat-stroked-button (click)="save()" type="submit" color="primary" [disabled]="fgRefer.invalid">{{ 'Save' | localize }}</button>
</mat-dialog-actions>

