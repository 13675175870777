import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class SnackBarService {

  constructor(private _snackBar: MatSnackBar) { }

  success(message: string, button?: string | ''): void {
    this._snackBar.open(message, button, { duration:3000 });
  }
  info(message: string, button?: string | ''): void {
    this._snackBar.open(message, button, { duration:3000 });
  }
  warn(message: string, button?: string | ''): void {
    this._snackBar.open(message, button, { duration:3000 });
  }
  error(message: string, button?: string | ''): void {
    this._snackBar.open(message, button, { duration:3000 });
  }
}