<mat-form-field>
  <mat-label>{{ "Products" | localize }}</mat-label>
  <input #productsInput autofocus type="text" placeholder="{{ 'SearchProduct' | localize }}" aria-label="" matInput
    [formControl]="productInput" [matAutocomplete]="product" [readonly]="disabledSearchProducts" />
  <mat-hint *ngIf="disabledSearchProducts">{{ 'Disabled' | localize }}</mat-hint>
  <mat-autocomplete autoActiveFirstOption #product="matAutocomplete" (optionSelected)="addProduct($event)">
    <mat-option *ngFor="let item of filteredProducts | async" [value]="item" class="mt-2 borderLineName">

      <div [hidden]="!showSplitName">
        <div *ngIf="!item.variantId" class="splitedName">
          <div class="splitedName">
            {{ item.name.split("/")[0] }}
          </div>
          <div class="splitedName" [matTooltip]="item.name">
            {{item.name.split("/")[1]}}
          </div>
        </div>

        <div *ngIf="item.variantId" class="splitedName">
          <div class="splitedName">
            {{ item.name.split("/").slice(0, 3).join(" / ") }}
          </div>
          <div class="splitedName" [matTooltip]="item.name">
            {{item.name.split("/")[3]}}
          </div>
        </div>
      </div>
      <div [hidden]="showSplitName" class="splitedName" [matTooltip]="item.name">
        {{item.name}}
      </div>
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
