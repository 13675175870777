import { TenantDto } from "./service-proxies/service-proxies";

export class AppConsts {

    static remoteServiceBaseUrl: string;
    static appBaseUrl: string;
    static appBaseHref: string; // returns angular's base-href parameter value if used during the publish   
    static tenant:TenantDto;
    static logo: string = '/assets/img/logo-maer.png';

    static localeMappings: any = [];

    static readonly userManagement = {
        defaultAdminUserName: 'admin'
    };

    static readonly localization = {
        defaultLocalizationSourceName: 'PosMaer'
    };

    static readonly authorization = {
        encryptedAuthTokenName: 'enc_auth_token'
    };

    static readonly icons = [
        'attach_file',
        'attach_money',
        'monetization_on',
        'cloud_queue',
        'credit_card',
        'account_balance',
        'account_balance_wallet',
        'money'
    ];
}

