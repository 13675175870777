import { Injectable } from '@angular/core';
import { HubConnection, HubConnectionState } from '@aspnet/signalr';
import { SignalRAspNetCoreHelper } from '@shared/helpers/SignalRAspNetCoreHelper';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class NotificationService {
    private hubConnection: HubConnection;
    private updateProductCacheSubject = new Subject<boolean>();
    private updateProductPricesSubject = new Subject<string>();
    private downloadnProductsReportSubject = new Subject<any>();
    private downloadnSellerMonthlyReportSubject = new Subject<any>();
    private reconnectInterval: number = 5000; // 5 seconds

    updateProductCache$ = this.updateProductCacheSubject.asObservable();
    updateProductPrices$ = this.updateProductPricesSubject.asObservable();
    donwloadProductReport$ = this.downloadnProductsReportSubject.asObservable();
    downloadnSellerMonthly$ = this.downloadnSellerMonthlyReportSubject.asObservable();

    constructor() {
        if (!this.hubConnection) {
            this.connect();
        } else if (this.hubConnection.state === HubConnectionState.Disconnected) {
            this.connect();
        }
    }

    private connect(): void {
        SignalRAspNetCoreHelper.initSignalR(() => {
            abp.signalr.startConnection('/signalr-notificationHub', (connection) => {
                this.hubConnection = connection;
                this.registerOnServerEvents()
                this.hubConnection.onclose(() => this.reconnect());
            }
            ).catch(err => {
                console.error('Error while starting SignalR connection: ', err);
                setTimeout(() => this.connect(), this.reconnectInterval);
            });
        });
    }

    private reconnect(): void {
        console.log('Connection lost. Attempting to reconnect...');
        setTimeout(() => this.connect(), this.reconnectInterval);
    }

    private registerOnServerEvents(): void {
        // Connect to Update Products Cache
        this.hubConnection.on('UpdateProductCache', (message: boolean) => {
            this.updateProductCacheSubject.next(message);
        });

        // Conect to update product Prices
        this.hubConnection.on('updateProductPrices', (message: string) => {
            this.updateProductPricesSubject.next(message);
        });

        // Connet to download product report
        this.hubConnection.on("DownloadProductsReport", (excelData: any) => {
            this.downloadnProductsReportSubject.next(excelData)
        });

        // Connect to download seller monthly report
        this.hubConnection.on("DownloadSellerMonthlyReport", (excelData: any) => {
            this.downloadnSellerMonthlyReportSubject.next(excelData)
        })
    }

    disconnect(): void {
        if (this.hubConnection && this.hubConnection.state !== HubConnectionState.Disconnected) {
            this.hubConnection.stop()
                .then(() => console.log('Disconnected from SignalR hub'))
                .catch(err => console.error('Error while disconnecting SignalR connection: ', err));
        }
    }

    onUpdateProductCache(): Observable<boolean> {
        return this.updateProductCache$;
    }

    onUpdateProductPrices(): Observable<string> {
        return this.updateProductPrices$;
    }

    onDownloadProductsReport(): Observable<any> {
        return this.donwloadProductReport$;
    }

    onDonwloadSellerMonthlyReport(): Observable<any> {
        return this.downloadnSellerMonthly$;
    }
}
