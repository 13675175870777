import { Component, Injector, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { InvoiceServiceProxy, ProductInvoiceDto, StockServiceProxy,StockMinDto} from '@shared/service-proxies/service-proxies';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith, switchMap } from 'rxjs/operators';
import { AppComponentBase } from '@shared/app-component-base';

@Component({
  templateUrl: './stock-minstock-menu.component.html',
  styleUrls: ['./stock-minstock-menu.component.scss']
})
export class StockMinstockMenuComponent extends AppComponentBase implements OnInit{

  saving = false;
  title: string;

  fgRefer: FormGroup;
  filteredProducts: Observable<ProductInvoiceDto[]>;

  productIds: number[]=[];
  MinStockDto: StockMinDto = new StockMinDto();

  newProduct: FormControl = this.fb.control('');

  constructor(
    injector: Injector,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<StockMinstockMenuComponent>,
    public dialog: MatDialog,

    public _stockService: StockServiceProxy,
    public _invoiceService: InvoiceServiceProxy,
  ) {
    super(injector);
  }

  get productsArr() {
    return this.fgRefer.controls["listProducts"] as FormArray;
  }

  ngOnInit() {
    this.title = 'MinStock';
    this.createReferForm();

    this.filteredProducts = this.fgRefer.get('productInput').valueChanges.pipe(
      startWith(''),
      debounceTime(400),
      distinctUntilChanged(),
      switchMap(val => {
        return this._stockService.getProductsWithVariantInStock(val || '')
      })
    );
  }


  createReferForm(): void {
    this.fgRefer = this.fb.group({
      productInput: [],
      listProducts: this.fb.array([], [Validators.required]),
      userId: [this.appSession.user.id],
      minStock: [0, [Validators.required]]
    });
  }

  createProduct(product: ProductInvoiceDto): FormGroup {
    return this.fb.group({
      colorId: [product.colorId],
      name: [product.name],
      productId: [product.id],
      productName: [product.name],
      sizeId: [product.sizeId],
      variantId: [product.variantId]
    })
  }

  deleteProduct(productIndex: number) {
    this.productsArr.removeAt(productIndex);
  }

  save() {
    // -- Save loading
    this.saving = true;

    const arrProductId = this.productsArr.value.filter(x => !x.variantId).map(p => p.productId);
    const arrVariantId = this.productsArr.value.filter(x => x.variantId).map(p => p.variantId);

    this.MinStockDto.productIds = arrProductId;
    this.MinStockDto.variantIds = arrVariantId;
    this.MinStockDto.stockMin = this.fgRefer.get('minStock').value;

    this._stockService.setMinimumStock(this.MinStockDto).subscribe(
      () => {
        this.notify.success(this.l('SavedSuccessfully'), this.l('Close'));
        this.dialogRef.close(true);
      }, () => {
        this.saving = false;
      }
    );
  }

  onNoClick(): void {
    abp.message.confirm(
      this.l('InformationWillBeLost'),    //traducir
      undefined,
      (result: boolean) => {
        if (result) {
          this.dialogRef.close();
        }
      }
    );
  }

  searchProductsFromComponent(data: any){
    const a = this.productsArr.value.findIndex(
      (item) =>
        item.productId === data.id && item.variantId === data.variantId
    );
    if (a != -1) {
      let productCount = this.productsArr.at(a).get('count').value as number;
      this.productsArr.at(a).get('count').setValue(productCount + data.quantity) ;
    } else {
      let product = this.createProduct(data)
      this.productsArr.push(product);
    }
  }
}
