import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogData } from '../models/confirm-dialog-data';
import { Observable } from 'rxjs';
import { ConfirmComponent } from '../components/dialogs/confirm/confirm.component';

@Injectable()
export class DialogService {
  constructor(private dialog: MatDialog) {}

  confirm(title: string, message: string): Observable<boolean> {
    let data = {
      title: title,
      message: message,
      confirmCaption:abp.localization.localize('Yes','PosMaer'),
      cancelCaption: abp.localization.localize('No','PosMaer')
    } as ConfirmDialogData;

    return this.dialog
      .open(ConfirmComponent, {
        data,
        width: '400px',
        disableClose: true,
      })
      .afterClosed();
  }
}
