<h2 mat-dialog-title>{{'Quantity' | localize}}</h2>

<div class="row">
  <div class="col-12">
    <mat-form-field>
      <mat-label>{{ 'Quantity' | localize }}</mat-label>
      <input matInput type="number" [(ngModel)]="quantity" autocomplete="off" (keyup.enter)="saveQuantity()"
        (focus)="onQuantityInputFocus($event)" autofocus/>
      <mat-hint *ngIf="!quantity">{{ 'AddQuantity' | localize }}</mat-hint><br/>
    </mat-form-field>
  </div>
  <div class="col-12">
    <button class="float-end" mat-raised-button (click)="saveQuantity()" color="primary" [disabled]="quantity == 0">
      {{ 'Ok' | localize }}
    </button>
  </div>
</div>
