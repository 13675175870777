<h2 mat-dialog-title>{{ title | localize}}</h2>
<form [formGroup]="fgRefer" (ngSubmit)="save()">

  <div class="row">
    <div class="col-8">
      <mat-form-field>
        <mat-label>{{ "Warehouse" | localize }}</mat-label>
        <mat-select formControlName="warehouseToId" required id="warehouse">
          <mat-option [value]="null">{{ "None" | localize }}</mat-option>
          <mat-option *ngFor="let item of warehouses" [value]="item.id">{{item.name}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="col-4">
      <mat-form-field>
        <mat-label>{{'Number' | localize}}</mat-label>
        <input type="text" formControlName="number" matInput placeholder="N°">
      </mat-form-field>
    </div>
  </div>

  <mat-form-field>
    <mat-label>{{'Description' | localize}}</mat-label>
    <textarea class="description-field" type="text" formControlName="description" rows="2" matInput></textarea>
  </mat-form-field>

  <mat-form-field>
    <mat-label>{{'Products' | localize}}</mat-label>
    <input autofocus type="text" placeholder="{{ 'SearchProduct' | localize }}" aria-label="" matInput
      formControlName="productInput" #productI [matAutocomplete]="product">
    <mat-autocomplete autoActiveFirstOption #product="matAutocomplete">
      <mat-option *ngFor="let item of filteredProducts | async" [value]="item" (click)="addProduct(item)">
        {{item.name}}
      </mat-option>
    </mat-autocomplete>
    <mat-icon matSuffix>search</mat-icon>
  </mat-form-field>

  <div class="row" formArrayName="listProducts">
    <table class="table table-fixed">
      <thead>
        <tr>
          <th>
            {{ 'Products' | localize }}
          </th>
          <th class="col-input">
            {{ 'Quantity' | localize }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of productsArr.controls; let i = index">

          <td>
            {{ item.value.name }}
          </td>

          <td [formGroupName]="i" class="col-input">
            <input type="number" formControlName="count" min="1">

            <button type="button" mat-icon-button color="warn" matTooltip="{{ 'Delete' | localize }}"
              aria-label="{{ 'Delete' | localize }}" (click)="deleteProduct(i)">
              <mat-icon>delete</mat-icon>
            </button>
          </td>
        </tr>
        <tr *ngIf="productsArr.length == 0">
          <td style="width: 600px;" colspan="2" class="text-center">{{ 'EmptyProducts'| localize }}</td>
        </tr>
      </tbody>
      <tfoot *ngIf="productsArr.length > 0">
        <tr>
          <th>{{ 'Total' | localize }}</th>
          <th class="col-input">{{getTotal()}}</th>
        </tr>
      </tfoot>
    </table>
  </div>

  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>{{'Cancel' | localize }}</button>
    <button mat-stroked-button type="submit" color="primary" [disabled]="fgRefer.invalid">{{ 'Save' | localize
      }}</button>
  </mat-dialog-actions>

</form>
