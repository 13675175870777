import { Injector, ElementRef } from "@angular/core";
import { AppConsts } from "@shared/AppConsts";
import {
  LocalizationService,
  PermissionCheckerService,
  FeatureCheckerService,
  NotifyService,
  SettingService,
  MessageService,
  AbpMultiTenancyService,
} from "abp-ng2-module";

import { AppSessionService } from "@shared/session/app-session.service";
import { LayoutStoreService } from "./layout/layout-store.service";
import { Router } from "@angular/router";
import { CookieService } from "ngx-cookie";
import { SnackBarService } from "./notifications/service-snackbar";
import { DialogService } from "./services/dialog.service";
class TableWidth {
  name: string;
  col: string;
  row: string;
}

export abstract class AppComponentBase {
  localizationSourceName = AppConsts.localization.defaultLocalizationSourceName;
  localization: LocalizationService;
  permission: PermissionCheckerService;
  feature: FeatureCheckerService;
  notify: SnackBarService;
  setting: SettingService;
  message: MessageService;
  multiTenancy: AbpMultiTenancyService;
  appSession: AppSessionService;
  elementRef: ElementRef;
  layoutStore: LayoutStoreService;
  router: Router;
  cookieService: CookieService;
  dialogService: DialogService;
  tableWidth: TableWidth[] = [
    { name: "25%", col: "3", row: "200px" },
    { name: "50%", col: "6", row: "200px" },
    { name: "75%", col: "9", row: "200px" },
    { name: "100%", col: "12", row: "200px" },
  ];
  constructor(injector: Injector) {
    this.localization = injector.get(LocalizationService);
    this.permission = injector.get(PermissionCheckerService);
    this.feature = injector.get(FeatureCheckerService);
    this.notify = injector.get(SnackBarService);
    this.setting = injector.get(SettingService);
    this.message = injector.get(MessageService);
    this.multiTenancy = injector.get(AbpMultiTenancyService);
    this.appSession = injector.get(AppSessionService);
    this.elementRef = injector.get(ElementRef);
    this.layoutStore = injector.get(LayoutStoreService);
    this.router = injector.get(Router);
    this.cookieService = injector.get(CookieService);
    this.dialogService = injector.get(DialogService);
  }

  l(key: string, ...args: any[]): string {
    let localizedText = this.localization.localize(
      key,
      this.localizationSourceName
    );

    if (!localizedText) {
      localizedText = key;
    }

    if (!args || !args.length) {
      return localizedText;
    }

    args.unshift(localizedText);
    return abp.utils.formatString.apply(this, args);
  }

  isGranted(permissionName: string): boolean {
    return this.permission.isGranted(permissionName);
  }
}
