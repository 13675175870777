import { Injectable } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { LayoutStoreService } from '@shared/layout/layout-store.service';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class NavService {
  public appDrawer: any;
  public currentUrl = new BehaviorSubject<string>(undefined);

  constructor(private router: Router,
              private layoutStore: LayoutStoreService) {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.currentUrl.next(event.urlAfterRedirects);
      }
    });
  }

  public closeNav() {
    if (this.appDrawer) {
      this.appDrawer.close();
    }

    setTimeout(() => {
      if (screen.width < 600) {
        this.layoutStore.setSidebarExpanded(false);
      }
    }, 100);
  }

  public openNav() {
    if (this.appDrawer) {
      this.appDrawer.open();
    }
  }
}
