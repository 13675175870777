import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProductInvoiceDto } from '@shared/service-proxies/service-proxies';

@Component({
  templateUrl: './add-quantity.component.html',
})
export class AddQuantityComponent {
  quantity: number = 1;
  product: ProductInvoiceDto = new ProductInvoiceDto();

  constructor(
    public dialogRef: MatDialogRef<AddQuantityComponent>,
  ) { }

  saveQuantity(){
    this.dialogRef.close(this.quantity);
  }

  onNoClick(): void {
    this.dialogRef.close(0);
  }

  onQuantityInputFocus(event: any) {
    event.target.select();
  }
}
