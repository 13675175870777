import { AppConsts } from '@shared/AppConsts';
import { UtilsService } from 'abp-ng2-module';

export class SignalRAspNetCoreHelper {
    private static isSignalRInitialized = false; // Bandera para verificar si SignalR ya fue inicializado
    private static initPromise: Promise<void> | null = null; // Promesa para manejar la carga única
    private static callbackQueue: (() => void)[] = []; // Cola de callbacks

    static initSignalR(callback?: () => void): Promise<void> {
        if (this.isSignalRInitialized) {
            console.log('SignalR ya está inicializado.');
            if (callback) {
                callback();
            }
            return Promise.resolve();
        }

        if (this.initPromise) {
            // Si ya hay una promesa en curso, agregamos el callback a la cola
            return this.initPromise.then(() => {
                if (callback) {
                    callback();
                }
            });
        }

        this.initPromise = new Promise<void>((resolve, reject) => {
            const encryptedAuthToken = new UtilsService().getCookieValue(AppConsts.authorization.encryptedAuthTokenName);

            abp.signalr = {
                autoConnect: true,
                connect: undefined,
                hubs: undefined,
                qs: AppConsts.authorization.encryptedAuthTokenName + '=' + encodeURIComponent(encryptedAuthToken),
                remoteServiceBaseUrl: AppConsts.remoteServiceBaseUrl,
                startConnection: undefined,
                url: '/signalr',
                withUrlOptions: null
            };

            const script = document.createElement('script');
            script.onload = () => {
                console.log('Script de SignalR cargado correctamente');
                this.isSignalRInitialized = true;
                resolve();

                // Ejecutar todos los callbacks en la cola
                this.callbackQueue.forEach(cb => cb());
                this.callbackQueue = []; // Limpiar la cola
            };
            script.onerror = (err) => {
                console.error('Error al cargar el script de SignalR');
                reject(err);
            };
            script.src = AppConsts.appBaseUrl + '/assets/abp/abp.signalr-client.js';
            document.head.appendChild(script);
        });

        // Mientras se carga, encola los callbacks
        if (callback) {
            this.callbackQueue.push(callback);
        }

        return this.initPromise;
    }
}
