import { Injectable } from '@angular/core';
import {  CanDeactivate,  UrlTree} from '@angular/router';
import { Observable } from 'rxjs';

@Injectable()
export class ConfirmGuard implements CanDeactivate<any> {
  canDeactivate():
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    let text = abp.localization.localize('DoYouWantToExitInvoice','PosMaer');
    return window.confirm(text);
  }
}
