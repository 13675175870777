<div class="header">
  <h2 mat-dialog-title *ngIf="data?.title != ''">{{data?.title}}</h2>
</div>

<div mat-dialog-content [align]="'center'">
  <br>
  <p>{{data?.message}}</p>
</div>

<div mat-dialog-actions [align]="'center'">
  <button mat-raised-button [mat-dialog-close]="false">
      {{data?.cancelCaption}}
  </button>
  <button mat-raised-button color="primary" [mat-dialog-close]="true">
      {{data?.confirmCaption}}
  </button>
</div>
