import { Component, Injector, OnInit } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { AppComponentBase } from '@shared/app-component-base';


@Component({
  selector: 'app-update',
  templateUrl: './update.component.html'
})
export class UpdateComponent extends AppComponentBase implements OnInit {

  title: string = '';
  question: string = '';
  showUpdate: boolean = false;

  constructor(private injector: Injector, private swUpdate: SwUpdate) {
    super(injector);

    this.title = this.l("AppNewVersionTitle");
    this.question = this.l("AppNewVersionQuestion");
    this.showUpdate = this.cookieService.get('Refresh') == 'true';

    window.onbeforeunload = (event) => {
      this.cookieService.remove('Refresh');
    };
  }

  ngOnInit(): void {

    // -- Update if is enable
    if (this.swUpdate.isEnabled) {
      this.swUpdate.available.subscribe(() => {
        this.cookieService.put('Refresh', 'true');
        this.showUpdate = true;
      });
    }

  }

  clickUpdate() {
    this.message.confirm(this.question, this.title, result => {
      if (result) {
        this.cookieService.remove('Refresh');
        location.reload();
      }
    });
  }

}
