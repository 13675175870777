import { Component, Injector, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ClientInvoiceDto, DeliveryNoteDto, InvoiceProductDto, InvoiceServiceProxy, ProductInvoiceDto, StockServiceProxy, WarehouseSelectDto } from '@shared/service-proxies/service-proxies';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, startWith, switchMap } from 'rxjs/operators';
import { AppComponentBase } from '@shared/app-component-base';
import { AddQuantityStockComponent } from '@app/stock/add-quantity-stock/add-quantity-stock.component';

@Component({
  templateUrl: './stock-transfer-menu.component.html',
  styleUrls: ['./stock-transfer-menu.component.scss']
})
export class StockTransferMenuComponent extends AppComponentBase implements OnInit {
  saving = false;
  title: string;

  fgRefer: FormGroup;
  filteredProducts: Observable<ProductInvoiceDto[]>;

  products: InvoiceProductDto[] = [];
  warehouses: WarehouseSelectDto[] = [];
  deliveryNoteDto: DeliveryNoteDto = new DeliveryNoteDto();

  newProduct: FormControl = this.fb.control('');

  constructor(
    injector: Injector,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<StockTransferMenuComponent>,
    public dialog: MatDialog,

    public _stockService: StockServiceProxy,
    public _invoiceService: InvoiceServiceProxy,
  ) {
    super(injector);
    this._stockService.getAllWarehouses().subscribe((result) => (this.warehouses = result));
  }

  get productsArr() {
    return this.fgRefer.controls["listProducts"] as FormArray;
  }

  ngOnInit() {
    this.title = 'Transfer';
    this.createReferForm();

    this.filteredProducts = this.fgRefer.get('productInput').valueChanges.pipe(
      startWith(''),
      debounceTime(400),
      distinctUntilChanged(),
      switchMap(val => {
        return this._stockService.getProductsWithVariantInStock(val || '')
      })
    );
  }


  createReferForm(): void {
    this.fgRefer = this.fb.group({
      warehouseFromId: ['', [Validators.required]],
      warehouseToId: ['', [Validators.required]],
      description: ['', [Validators.maxLength(64), Validators.minLength(20)]],
      productInput: [],
      listProducts: this.fb.array([], [Validators.required]),
      userId: [this.appSession.user.id],
      salePointId: [this.appSession.user.salePointConfigId]
    });
  }

  createProduct(product: ProductInvoiceDto): FormGroup {
    return this.fb.group({
      colorId: [product.colorId],
      count: [product.quantity, [Validators.required, Validators.min(1)]],
      discount: [0],
      iva: [0],
      name: [product.name],
      price: [product.price],
      productId: [product.id],
      productName: [product.name],
      sizeId: [product.sizeId],
      variantId: [product.variantId]
    })
  }

  deleteProduct(productIndex: number) {
    this.productsArr.removeAt(productIndex);
  }

  getTotal(): number{
    let total = this.productsArr.value.reduce((sum, current) => sum + current.count, 0);
    return total;
  }

  save() {
    // -- Save loading
    this.saving = true;
    this._invoiceService.createDeliveryNote(this.fgRefer.value as DeliveryNoteDto).subscribe(
      () => {
        this.notify.success(this.l('SavedSuccessfully'), this.l('Close'));
        this.dialogRef.close(true);
      }, () => {
        this.saving = false;
      }
    );
  }

  onNoClick(): void {
    abp.message.confirm(
      this.l('InformationWillBeLost'),    //traducir
      undefined,
      (result: boolean) => {
        if (result) {
          this.dialogRef.close();
        }
      }
    );
  }

  searchProductsFromComponent(data: any){
    const a = this.productsArr.value.findIndex(
      (item) =>
        item.productId === data.id && item.variantId === data.variantId
    );
    if (a != -1) {
      let productCount = this.productsArr.at(a).get('count').value as number;
      this.productsArr.at(a).get('count').setValue(productCount + data.quantity) ;
    } else {
      let product = this.createProduct(data)
      this.productsArr.push(product);
    }
  }
}
