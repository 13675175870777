import { Component, Input, OnInit, Output, EventEmitter, Injector } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AddQuantityComponent } from '@app/invoices/create-invoice/add-quantity/add-quantity.component';
import { AppComponentBase } from '@shared/app-component-base';
import { InvoiceServiceProxy, ProductInvoiceDto, TaxServiceProxy } from '@shared/service-proxies/service-proxies';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith, switchMap } from 'rxjs/operators';


@Component({
  selector: 'app-search-products',
  templateUrl: './search-products.component.html'
})

export class SearchProductsComponent extends AppComponentBase implements OnInit {

  keyword = '';
  filteredProducts: Observable<ProductInvoiceDto[]>;
  listOption: ProductInvoiceDto[];
  isChange: boolean = false;
  validMinProduct: number = 1;
  productInput = new FormControl;
  listProducts: FormArray = new FormArray([]);

  @Input() input: string;
  @Input() addQuantity: boolean;
  @Input() disabledSearchProducts: boolean;
  @Output() newData = new EventEmitter<any>();
  @Input() showStockQuantity: boolean;
  @Input() showSplitName: boolean = false;
  @Input() searchForInvoice: boolean = false;
  @Input() priceListId: number | undefined;
  @Input() salePointId: number;
  @Input() storeId: number | undefined;

  constructor(
    _injector: Injector,
    public _invoiceService: InvoiceServiceProxy,
    public _taxService: TaxServiceProxy,
    private _router: Router,
    private fb: FormBuilder,
    public dialog: MatDialog
  ) {
    super(_injector);
    this.isChange = this._router.url === "/invoices/changes";
    this.validMinProduct = this.isChange ? -9999 : 1;
  }

  ngOnInit(): void {
    //Get Products
    this.filteredProducts = this.productInput
      .valueChanges.pipe(
        startWith(""),
        debounceTime(300),
        distinctUntilChanged(),
        switchMap((val) => {

          if (!this.searchForInvoice) {
            return this._invoiceService
              .getProductsWithVariant(val || "")
              .pipe(map((items) => (this.listOption = items)));
          } else {

            return this._invoiceService.getProductsWithVariantsToInvoice(val || "", this.salePointId, this.priceListId)
              .pipe(map((items) => (this.listOption = items)));
          }
        })
      );

  }

  addProduct(event: any) {
    let product = event.option.value as ProductInvoiceDto;

    //add quantity to product
    if (this.addQuantity) {
      let dialogRef = this.dialog.open(AddQuantityComponent, {
        maxWidth: "250px",
      });
      // clear search input text
      this.productInput.reset();

      dialogRef.afterClosed().subscribe((result: number) => {
        if (!result || result == 0) return;

        this._invoiceService.getProductWithVariant(product.isVariant ? product.variantId : product.id, product.isVariant, this.priceListId, this.storeId)
          .subscribe(pro => {
            product = pro;

            const a = this.listProducts.value.findIndex(
              (item) =>
                item.productId === product.id && item.variantId === product.variantId
            );
            const exists = this.listProducts.value.find(
              (item) =>
                item.productId === product.id && item.variantId === product.variantId
            );
            if (exists) {
              exists.count = exists.count + result;
              product.quantity = exists.count;
              this.listProducts.removeAt(a);
            } else {
              product.quantity = Number(result);
            }
            //Check if product has stock
            if (abp.setting.getBoolean("Has.Stock") && product.stock < 1 && this.showStockQuantity) {
              this.notify.warn(`${product.name} sin stock`);
            }

            if (
              (!this.isChange && product.quantity > 0) ||
              (this.isChange && product.quantity != 0)
            ) {
              const formGroup = this.createProductInvoiceForm(product);
              this.listProducts.push(formGroup);
              this.newData.emit(product);
              this.listProducts.reset()
            }
          });
      });
    } else {
      // clear search input text
      this.productInput.reset();
      //Check if product has stock
      if (abp.setting.getBoolean("Has.Stock") && product.stock < 1 && this.showStockQuantity) {
        this.notify.warn(`${product.name} sin stock`);
      }

      const formGroup = this.createProductInvoiceForm(product);
      this.listProducts.push(formGroup);
      this.newData.emit(product);
      this.listProducts.reset()
    }
  }

  createProductInvoiceForm(obj: ProductInvoiceDto): FormGroup {
    return this.fb.group(
      {
        productId: [obj.id],
        productName: [obj.name],
        productCode: [obj.code],
        description: [obj.description],
        iva: [obj.taxValue],
        price: [obj.price],
        basePrice: [obj.basePrice],
        count: [
          obj.quantity,
          [
            Validators.required,
            Validators.min(this.validMinProduct),
            Validators.max(9999),
          ],
        ],
        listDiscounts: [[]],
        variantId: [obj.variantId],
        totalDiscount: [],
        listPromotionsIcons: [[]],
        stock: [obj.stock, [Validators.min(this.validMinProduct)]],
        gtin: [obj.gtin]
      },
      {
        validators: this.validateStock,
      }
    );
  }

  validateStock: ValidatorFn = (
    formgroup: FormGroup
  ): ValidationErrors | null => {
    const count = formgroup.get("count");
    const stock = formgroup.get("stock");
    // Skip validation if configuration dont set has.stock
    if (!abp.setting.getBoolean("Has.Stock")) {
      return null;
    }
    if (count.value > stock.value) {
      return { noStock: true };
    }
    return null;
  };
}
