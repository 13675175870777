import { Component, Injector, OnInit } from "@angular/core";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";
import {
  ClientInvoiceDto,
  DeliveryNoteDto,
  InvoiceProductDto,
  InvoiceServiceProxy,
  ProductInvoiceDto,
  StockServiceProxy,
  WarehouseSelectDto,
} from "@shared/service-proxies/service-proxies";
import { Observable } from "rxjs";
import {
  debounceTime,
  distinctUntilChanged,
  startWith,
  switchMap,
} from "rxjs/operators";
import { AppComponentBase } from "@shared/app-component-base";

@Component({
  templateUrl: "./stock-menu.component.html",
  styleUrls: ["./stock-menu.component.scss"],
})
export class StockMenuComponent extends AppComponentBase implements OnInit {
  saving = false;
  title: string;

  fgRefer: FormGroup;
  filteredProducts: Observable<ProductInvoiceDto[]>;

  products: InvoiceProductDto[] = [];
  warehouses: WarehouseSelectDto[] = [];
  deliveryNoteDto: DeliveryNoteDto = new DeliveryNoteDto();

  newProduct: FormControl = this.fb.control("");

  constructor(
    injector: Injector,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<StockMenuComponent>,

    public _stockService: StockServiceProxy,
    public _invoiceService: InvoiceServiceProxy
  ) {
    super(injector);
    this._stockService
      .getAllWarehouses()
      .subscribe((result) => (this.warehouses = result));
  }

  get productsArr() {
    return this.fgRefer.controls["listProducts"] as FormArray;
  }

  ngOnInit() {
    this.title = "DeliveryNote";
    this.createReferForm();
    this.filteredProducts = this.fgRefer.get("productInput").valueChanges.pipe(
      startWith(""),
      debounceTime(400),
      distinctUntilChanged(),
      switchMap((val) => {
        return this._stockService.getProductsWithVariantInStock(val || "");
      })
    );
  }

  createReferForm(): void {
    this.fgRefer = this.fb.group({
      number: ["", Validators.required],
      warehouseToId: ["", [Validators.required]],
      description: [
        "",
        [
          Validators.required,
          Validators.maxLength(64),
          Validators.minLength(20),
        ],
      ],
      productInput: [],
      listProducts: this.fb.array([], [Validators.required]),
      userId: [this.appSession.user.id],
    });
  }

  createProduct(product: ProductInvoiceDto): FormGroup {
    return this.fb.group({
      colorId: [product.colorId],
      count: [1, [Validators.required, Validators.min(1)]],
      discount: [0],
      iva: [0],
      name: [product.name],
      price: [product.price],
      productId: [product.id],
      productName: [product.name],
      sizeId: [product.sizeId],
      variantId: [product.variantId],
    });
  }

  addProduct(product: ProductInvoiceDto): void {
    this.fgRefer.get("productInput").reset();

    const exists = this.productsArr.value.find(
      (item) =>
        item.productId === product.id && item.variantId === product.variantId
    );
    if (exists) {
      return;
    }

    const formGroup = this.createProduct(product);
    this.productsArr.push(formGroup);
  }

  deleteProduct(productIndex: number) {
    this.productsArr.removeAt(productIndex);
  }

  getTotal(): number {
    let total = this.productsArr.value.reduce(
      (sum, current) => sum + current.count,
      0
    );
    return total;
  }

  save() {
    // -- Save loading
    this.saving = true;
    this._invoiceService
      .createDeliveryNote(this.fgRefer.value as DeliveryNoteDto)
      .subscribe(
        () => {
          this.notify.success(this.l("SavedSuccessfully"), this.l("Close"));
          this.dialogRef.close(true);
        },
        () => {
          this.saving = false;
        }
      );
  }
}
