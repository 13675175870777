<h2 mat-dialog-title>{{ title | localize}}</h2>
<form [formGroup]="fgRefer" (ngSubmit)="save()">

  <div class="row">
    <div class="col-5">
      <mat-form-field>
        <mat-label>{{ "Warehouse" | localize }}</mat-label>
        <mat-select formControlName="warehouseFromId" required id="warehouse">
          <mat-option [value]="null">{{ "None" | localize }}</mat-option>
          <mat-option *ngFor="let item of warehouses" [value]="item.id">{{item.name}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-2 middle-transfer">
      <strong>>></strong>
    </div>
    <div class="col-5">
      <mat-form-field>
        <mat-label>{{ "Warehouse" | localize }}</mat-label>
        <mat-select formControlName="warehouseToId" required id="warehouse">
          <mat-option [value]="null">{{ "None" | localize }}</mat-option>
          <mat-option *ngFor="let item of warehouses" [value]="item.id" [disabled]="fgRefer.get('warehouseFromId').value == item.id">{{item.name}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <mat-form-field>
    <mat-label>{{'Description' | localize}}</mat-label>
    <textarea class="description-field" type="text" formControlName="description" rows="2" matInput></textarea>
  </mat-form-field>

  <!-- Search Products -->
  <app-search-products [input]="'productInput'" [showSplitName]="true" [addQuantity]="true" [showStockQuantity]="true" (newData)="searchProductsFromComponent($event)"></app-search-products>

  <div class="row" formArrayName="listProducts">
    <table class="table table-fixed">
      <thead>
        <tr>
          <th>
            {{ 'Products' | localize }}
          </th>
          <th class="col-input">
            {{ 'Quantity' | localize }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of productsArr.controls; let i = index">

          <td>
            {{ item.value.name }}
          </td>

          <td [formGroupName]="i" class="col-input">
            <input type="number" formControlName="count" min="1">

            <button type="button" mat-icon-button color="warn" matTooltip="{{ 'Delete' | localize }}"
              aria-label="{{ 'Delete' | localize }}" (click)="deleteProduct(i)">
              <mat-icon>delete</mat-icon>
            </button>
          </td>
        </tr>
        <tr *ngIf="productsArr.length == 0">
          <td style="width: 600px;" colspan="2" class="text-center">{{ 'EmptyProducts'| localize }}</td>
        </tr>
      </tbody>
      <tfoot *ngIf="productsArr.length > 0">
        <tr>
          <th>{{ 'Total' | localize }}</th>
          <th class="col-input">{{getTotal()}}</th>
        </tr>
      </tfoot>
    </table>
  </div>

</form>

<mat-dialog-actions align="end">
  <button mat-button (click)="onNoClick()">{{'Cancel' | localize }}</button>
  <button mat-stroked-button (click)="save()" type="submit" color="primary" [disabled]="fgRefer.invalid">{{ 'Save' | localize
    }}</button>
</mat-dialog-actions>
