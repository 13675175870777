import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { StockMinstockMenuComponent } from '@shared/components/menu-btn-sheet/stock-minstock-menu/stock-minstock-menu.component';
import { StockTransferMenuComponent } from '@shared/components/menu-btn-sheet/stock-transfer-menu/stock-transfer-menu.component';
import { BehaviorSubject } from 'rxjs';
import { StockMenuComponent } from '../components/menu-btn-sheet/stock-menu/stock-menu.component';

@Injectable()
export class StockService {

  public dialogClose: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(private dialog: MatDialog) {}

  openPopupStockMenu(): void {
      let dial = this.dialog.open(StockMenuComponent, {
        width: '500px',
      });

      dial.afterClosed().subscribe(isClose =>{
        this.dialogClose.next(isClose);
      });
  }

  openPopupStockTransferMenu(): void {
    let dial = this.dialog.open(StockTransferMenuComponent, {
      disableClose: true,
      width: '500px',
    });

    dial.afterClosed().subscribe(isClose =>{
      this.dialogClose.next(isClose);
    });
}
  openPopupMinStockMenu(): void {
    let dial = this.dialog.open(StockMinstockMenuComponent, {
      disableClose: true,
      width: '500px'
    });

    dial.afterClosed().subscribe(isClose =>{
      this.dialogClose.next(isClose);
    });
  }
}
