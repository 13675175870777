import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable()
export class GatewayPaymentOrderService {

 public isPaymentSuccessfully$: Subject<boolean> = new Subject<boolean>();

  setIsPaymentSuccessfully(isPaid: boolean): void {
   this.isPaymentSuccessfully$.next(isPaid);
  }
}
